
import { Component } from 'nuxt-property-decorator'
import CustomSwiper from '~/components/CustomSwiper.vue'
import PaintingRate from '~/components/index/PaintingRate.vue'

@Component({
  components: {
    PaintingRate
  }
})
export default class extends CustomSwiper {}
