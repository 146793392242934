
import { Component, Prop, Ref, Vue } from 'nuxt-property-decorator'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import '~/assets/scss/swiper.css'
import PaintingRate from '~/components/index/PaintingRate.vue'
import BuyPainting from '~/components/buy/BuyPainting.vue'
import { Painting } from '~/types/dto/Painting'
import MerchNotify from '~/components/MerchNotify.vue'

Vue.use(VueAwesomeSwiper)

@Component({
  components: {
    MerchNotify,
    PaintingRate,
    BuyPainting
  },
  directives: {
    swiper: VueAwesomeSwiper.directive
  }
})
export default class extends Vue {
  @Ref('paintingSwiper') paintingSwiper!: any

  @Prop() paintings!: Painting[]
  @Prop() value!: number

  rating: string = ''
  painting: object = {}
  ratingId = ''
  isShowBuyPainting: boolean = false
  isShow: boolean = true
  timer: any = null
  options: any = {
    spaceBetween: 10,
    centeredSlides: true,
    loop: false,
    speed: 300,
    slidesPerView: 1.3,
    mousewheel: true,
    keyboard: {
      enabled: true
    },
    breakpoints: {
      480: {
        slidesPerView: 1.6
      },
      750: {
        slidesPerView: 2,
        spaceBetween: 60
      }
    }
  }

  notifyPainting: Painting | null = null

  mounted () {
    this.$eventBus.$on('isShowSwiper', (isShowSwiper) => {
      this.isShowBuyPainting = isShowSwiper
    })

    this.$eventBus.$on('PromoSend', this.onSlide)

    this.onSlide()
  }

  buyPainting (paint) {
    this.isShowBuyPainting = !this.isShowBuyPainting
    this.painting = { id: paint.id, name: paint.artist['name_' + this.$i18n.locale] }
    this.$eventBus.$emit('isShowBuyPainting', this.isShowBuyPainting)
    this.$eventBus.$emit('painting', this.painting)
    this.isShow = false
  }

  fullScreenData (paint, index) {
    this.rating = paint.rate
    this.ratingId = paint.id

    if (this.value === index) {
      this.$eventBus.$emit('SHOW_FULLSCREEN', {
        rate: paint.rate,
        paintId: paint.id,
        // merchSaleAgree: paint.merch_sale_agree,
        image: paint.original_file,
        userCurrentRate: paint.user_current_rate
      })
    } else {
      this.setActive(index)
    }
  }

  setActive (index: number) {
    this.paintingSwiper.swiper.slideTo(index)
  }

  onSlide () {
    // if (this.timer) {
    //   clearTimeout(this.timer)
    // }
    // if (
    //   !localStorage.getItem('merch') &&
    //   this.paintings[this.paintingSwiper.swiper.activeIndex].wear_merchandise &&
    //   localStorage.getItem('promo') &&
    //   this.paintings[this.paintingSwiper.swiper.activeIndex].artist.merch_sale_agree
    // ) {
    //   this.timer = setTimeout(() => {
    //     this.notifyPainting = this.paintings[this.paintingSwiper.swiper.activeIndex]
    //     localStorage.setItem('merch', 'true')
    //   }, 5_000)
    // }

    this.$emit('input', this.paintingSwiper.swiper.activeIndex)
  }
}
