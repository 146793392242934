
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { Painting } from '~/types/dto/Painting'

@Component
export default class extends Vue {
  @Prop() painting!: Painting

  mounted () {
    setTimeout(() => {
      this.$emit('close')
    }, 3_000)
  }
}
